import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from "vue2-google-maps";
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import key from '@/utils/googleApis';
import VueMask from 'v-mask';

Vue.config.productionTip = false;

Vue.use(VueMask);
Vue.use(VueGoogleMaps, {
  load: {
    key: key,
    libraries: "places,geometry"
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
