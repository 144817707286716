import axios from 'axios';
import { googleTime, setGoogleTime, canFetchGoogleLocation } from '@/utils/localStorage'

const api_key = "AIzaSyDK5iM4CEfXf4UYTMcxOVV4KsUoFyQ_Ozg";
export default api_key;

export const getLocation = async(position, id) => {
    let response = null;
    if(googleTime(id)) {
        if(canFetchGoogleLocation(id)) {
            const {lat, lng} = position;
            const {data} = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${api_key}&components=postal_code`
            );
            let zipcode;
            let address = '';
            if(data.results) {
                data.results[0].address_components.forEach(item => {
                    if(item.types[0] === 'postal_code'){
                        zipcode = item.short_name;
                    } else {
                        address = address + ' ' + item.short_name;
                    }
                });
            }
            response = { zipcode: zipcode, address: address }
        }
        else {
            response = {
                zipcode: googleTime(id).zipcode,
                address: googleTime(id).address
            }
        }
    }
    else {
        const {lat, lng} = position;
        const {data} = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${api_key}&components=postal_code`);
        let zipcode = '';
        let address = '';

        data.results[0].address_components.forEach(item => {
            if(item.types[0] === 'postal_code'){
                zipcode = item.short_name;
            } else {
                address = address + ' ' + item.short_name;
            }
        });
        response = { zipcode, address };
        setGoogleTime(zipcode, address, id);
    }

    return response;
}
