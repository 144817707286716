export const setToken = data => {
    let curTime = new Date().getTime();
    curTime += data.ttl * 1000;

    localStorage.setItem('token', data.id);
    localStorage.setItem('expiresIn', JSON.stringify(curTime));
};

export const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresIn');
};

export const getToken = () => localStorage.getItem('token');

export const hasExpired = () => {
    const expiresIn = JSON.parse(localStorage.getItem('expiresIn'));
    if(expiresIn) {
        const curTime = new Date().getTime();
        return curTime > expiresIn;
    } else {
        return true;
    }
};
