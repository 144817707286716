<template>
    <v-app>
        <router-view />
    </v-app>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        methods: {
            ...mapActions(["getOrders"])
        },
        mounted() {
            document.title = 'TT Freight | Dispatch Board';
        }
    };
</script>

<style>
    @import url("//fonts.googleapis.com/css?family=Oxanium&subset=latin");

    body, * {
        font-family: 'Oxanium', sans-serif;
        scroll-behavior: smooth;
    }
</style>
