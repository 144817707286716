import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('@/views/Home'),
    },
    {
        path: '/order/:id',
        component: () => import('@/views/Order'),
    },
    {
          path: '/authenticate',
          component: () => import('@/views/Authenticate')
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
