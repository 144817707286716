import axios from 'axios';

const baseURL = 'https://app.ttfreight.com/api';

const myAxios = axios.create({ baseURL });
myAxios.interceptors.request.use(config => {
    config.params = {
        access_token: localStorage.getItem('token'),
    };
    return config;
});

export default myAxios;
