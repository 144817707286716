import Vue from 'vue';
import Vuex from 'vuex';

import axios from '@/utils/axios';

Vue.use(Vuex);

const state = {
    orders: [],
    error: null,
    loading: false
};

const getters = {
    orders: state => state.orders,
    error: state => state.error,
    loading: state => state.loading
};
const actions = {
    async getOrders({commit}, status='calculating') {
        try {
            const val = status === 'none' ? '' : status;
            commit('setLoading', true);
            const {data} = await axios(`dispatchers/orders?page=1&status=${val}`);
            if(status === "none") {
                const orders = data.data.filter(el => (el.status !== "calculating" && el.status !== "finished"));
                commit('setOrders', orders);
            } else {
                commit('setOrders', data.data);
            }
        } catch (err) {
            commit('setError', 'Something went wrong');
        } finally {
            commit('setLoading', false);
        }
    },

    async updateOrder({ commit }, order) {
        try {
            commit('setLoading', true);
            const {data} = await axios(`mains/editorder/${order._id}`, {
                method: 'POST',
                data: order
            });
        }
        catch (err) {
            commit('setError', 'Order Update Error');
        }
        finally {
            commit('setLoading', false);
        }
    },

    setLoading({commit}, bool) { commit('setLoading', bool) }
};

const mutations = {
    setOrders: (state, orders) => state.orders = orders,
    setError: (state, error) => state.error = error,
    setLoading: (state, bool) => state.loading = bool,
};

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
})
